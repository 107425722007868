"use client";

import { createContext, useState, useEffect, useCallback } from "react";
import { getUserAuth } from "@/app/lib/auth";
import { getUserNotifications } from "@/app/utils/auth";
import { getListOfAllDaos, fetchBookmarksData } from "@/app/lib/apis";

type TDaoData = {
  id: string;
  name: string;
  avatar: string;
};

interface IDataContext {
  notifications: any[];
  daos: TDaoData[];
  loadingNotifications: boolean;
  bookmarkedDaos: string[];
  notificationsPage: number;
  hasMoreNotifications: boolean;
  loadMoreNotifications: () => void;
  setNotificationsPage: (notificationsPage: number) => void;
  setBookmarkedDaos: (daos: string[]) => void;
  setHasMoreNotifications: (hasMoreNotifications: boolean) => void;
}

export const DataContext = createContext<IDataContext>({
  daos: [],
  notifications: [],
  loadingNotifications: true,
  bookmarkedDaos: [],
  notificationsPage: 1,
  hasMoreNotifications: false,
  setBookmarkedDaos: () => {},
  setNotificationsPage: () => {},
  setHasMoreNotifications: () => {},
  loadMoreNotifications: () => {},
});
DataContext.displayName = "DataContext";

export function DataProvider({ children }: { children: React.ReactNode }) {
  const [daos, setDaos] = useState<TDaoData[]>([]);
  const [bookmarkedDaos, setBookmarkedDaos] = useState<string[]>([]);

  const [notifications, setNotifications] = useState<any[]>([]);
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(true);
  const [notificationsPage, setNotificationsPage] = useState<number>(1);
  const [hasMoreNotifications, setHasMoreNotifications] =
    useState<boolean>(false);

  // Fetch list of DAOs and bookmarks
  useEffect(() => {
    // Reset state
    setDaos([]);
    setBookmarkedDaos([]);

    (async () => {
      const daos = await getListOfAllDaos();
      const bookmarks = await fetchBookmarksData();
      setDaos(daos);
      setBookmarkedDaos(bookmarks);
    })();
  }, []);

  // Fetch notifications
  useEffect(() => {
    (async () => {
      const { account } = await getUserAuth();
      if (!account) return;
      const notifications = await getUserNotifications(
        account,
        notificationsPage,
      );

      setNotifications((oldNotifications) => {
        const newNotifications = notifications.filter(
          (notification: any) =>
            !oldNotifications.some(
              (n: any) => n.payload_id === notification.payload_id,
            ),
        );
        return [...oldNotifications, ...newNotifications];
      });
      setHasMoreNotifications(notifications.length > 0);
      setLoadingNotifications(false);
    })();
  }, [notificationsPage]);

  const loadMoreNotifications = useCallback(() => {
    setNotificationsPage((page) => page + 1);
    setLoadingNotifications(true);
  }, []);

  return (
    <DataContext.Provider
      value={{
        daos,
        notifications,
        loadingNotifications,
        bookmarkedDaos,
        notificationsPage,
        hasMoreNotifications,
        setBookmarkedDaos,
        setNotificationsPage,
        setHasMoreNotifications,
        loadMoreNotifications,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
