"use client";
import { createContext } from "react";
import useModal from "@/app/hooks/useModal";
import Modal from "@/app/components/Modal";

interface IModalContext {
  modal: boolean;
  modalType: string | null;
  modalData: any;
  handleModal: (type: string | null, modalData: any) => void;
}

export const ModalContext = createContext<IModalContext>({
  modal: false,
  modalType: null,
  modalData: null,
  handleModal: () => {},
});
ModalContext.displayName = "ModalContext";

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const { modal, modalType, modalData, handleModal } = useModal();

  return (
    <ModalContext.Provider value={{ modal, modalType, modalData, handleModal }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
}
