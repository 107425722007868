import { PushAPI } from "@pushprotocol/restapi";
import { PUSH_ENV, DHIVE_CHANNEL, CHAIN_ID } from "@/app/utils/constants";

import { useState, MouseEvent } from "react";
import styles from "./styles.module.css";
import { getSigninInfo } from "@/app/utils/auth";

import Loader from "@/app/components/Loader";
import { parseErrorMessage } from "@/app/utils/functions";

const SUBSCRIBE_STEPS = [
  {
    id: "initialize",
    title: "Initialize user",
    description:
      "Sign a message in your wallet to initialize your Push Protocol user",
  },
  {
    id: "switch",
    title: "Switch network",
    description: "Switch to the Ethereum Mainnet network in your wallet",
  },
  {
    id: "approve",
    title: "Approve action",
    description: "Approve changes to update your notification preferences",
  },
];

function Steps({ step }: { step: number }) {
  const text = `Step ${step + 1} of 3`;
  return (
    <div className={styles.steps}>
      <p>{text}</p>
      <div>
        {Array.from({ length: 3 }).map((_, index) => {
          const stepClassName =
            index < step ? styles.activeStep : styles.inactiveStep;
          return <span key={index} className={stepClassName}></span>;
        })}
      </div>
    </div>
  );
}

function StepDescription({ step }: { step: number }) {
  const description = SUBSCRIBE_STEPS[step].description;
  return <p>{description}</p>;
}

function ActionBtn({
  label,
  pushUser,
  setPushUser,
  step,
  setStep,
  setError,
  handleModal,
  modalData,
}: {
  label: string;
  pushUser: any;
  setPushUser: (pushUser: any) => void;
  step: number;
  setStep: (step: number) => void;
  setError: (error: string | null) => void;
  handleModal: (type: string | null, modalData: any) => void;
  modalData: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const values = Object.values(modalData);
  const isSubscribe = values.some((item) => item === true);

  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const value = Number(event.currentTarget.value);

    // Set states
    setLoading(true);
    setError(null);

    try {
      // Step 0: Initialize user API
      if (value === 0) {
        const { provider, chainId } = await getSigninInfo();
        const signer = await provider.getSigner();

        const pushUser = await PushAPI.initialize(signer, {
          env: PUSH_ENV,
        });

        if (pushUser?.errors?.length > 0) {
          throw Error("Failed to initialize Push Protocol user");
        }

        if (chainId !== BigInt(CHAIN_ID)) {
          setStep(1);
        } else {
          setStep(1);
          // Wait for 1 second so the user can see the network check
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setStep(2);
        }
        setPushUser(pushUser);
        setLoading(false);
        return;
      }

      // Step 1: Switch to the correct network (if needed)
      if (value === 1) {
        const { provider, chainId } = await getSigninInfo();

        if (chainId !== BigInt(CHAIN_ID)) {
          // Chain id to hex
          const chainIdHex = `0x${Number(CHAIN_ID).toString(16)}`;
          await provider.send("wallet_switchEthereumChain", [
            {
              chainId: chainIdHex,
            },
          ]);
        }

        // Update the state
        setStep(2);
        setLoading(false);
        return;
      }

      // Step 2: Update notification preferences
      if (value === 2) {
        const { chainId } = await getSigninInfo();
        const channel = `eip155:${CHAIN_ID}:${DHIVE_CHANNEL}`;

        if (!pushUser) {
          throw Error("Failed to initialize Push Protocol user");
        }

        // Check if the user is on the correct network
        if (chainId !== BigInt(CHAIN_ID)) {
          throw Error("Please switch to the correct network");
        }

        if (isSubscribe) {
          // Subscribe to the channel
          await pushUser.notification.subscribe(channel, {
            settings: [
              // settings are dependent on channel
              { enabled: values[0] }, // setting 1
              { enabled: values[1] }, // setting 2
              { enabled: values[2] }, // setting 3
            ],
          });
        } else {
          // Unsubscribe from the channel
          await pushUser.notification.unsubscribe(channel);
        }

        // Update the state
        setStep(3);
        setLoading(false);

        // Refresh the page
        window.location.reload();

        // Close the modal
        handleModal(null, null);
      }
    } catch (error: any) {
      const message = parseErrorMessage(error);
      // Reset loading state
      setLoading(false);
      setError(message);
      console.error(error);
    }
  };

  return (
    <button
      className={styles.actionSubscribeBtn}
      onClick={handleOnClick}
      value={step}
      disabled={loading}
    >
      {loading && <Loader size={16} />}
      {label}
    </button>
  );
}

function CancelBtn({
  handleModal,
  setStep,
  setError,
}: {
  handleModal: (type: string | null, modalData: any) => void;
  setStep: (step: number) => void;
  setError: (error: string | null) => void;
}) {
  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // Reset the state
    setStep(0);
    setError(null);

    // Close the modal
    handleModal(null, null);

    // Refresh the page
    window.location.reload();
  };

  return (
    <button className={styles.cancelSubscribeBtn} onClick={handleOnClick}>
      Cancel
    </button>
  );
}

export default function SubscribeModal({
  handleModal,
  modalData,
}: {
  handleModal: (type: string | null, modalData: any) => void;
  modalData: any;
}) {
  const [pushUser, setPushUser] = useState<any>(null);
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const title = SUBSCRIBE_STEPS[step]?.title || "";
  const isSubscribe = Object.values(modalData).some((item) => item === true);
  const processTitle = isSubscribe ? "Subscribe" : "Unsubscribe";

  if (step >= SUBSCRIBE_STEPS.length) {
    return null;
  }

  return (
    <div className={styles.subscribeModalCard}>
      <Steps step={step} />
      <div className={styles.subscribeModalCardBody}>
        <span>{processTitle}</span>
        <h4>{title}</h4>
        <StepDescription step={step} />
        {error && <p className={styles.subscribeModalError}>{error}</p>}
        <hr />
        <div className={styles.subscribeModalCardBtns}>
          <ActionBtn
            label={title}
            pushUser={pushUser}
            setPushUser={setPushUser}
            step={step}
            setStep={setStep}
            handleModal={handleModal}
            setError={setError}
            modalData={modalData}
          />
          <CancelBtn
            handleModal={handleModal}
            setStep={setStep}
            setError={setError}
          />
        </div>
      </div>
    </div>
  );
}
