"use client";
import { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { IAvatar } from "@/app/types";
import { fixIPFSImgURL } from "@/app/utils/functions";
import { IMAGE_PLACEHOLDER } from "@/app/utils/constants";

export default function Avatar(props: IAvatar) {
  const { src, size } = props;
  const style = {
    background: src ? "transparent" : "#BCDBFC",
    width: size,
    height: size,
  };
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!src) return;
    if (!imgRef?.current) return;

    const img = imgRef?.current;
    const imgSrc = fixIPFSImgURL(src);

    if (!imgSrc) return;
    img.src = imgSrc;
    img.onload = () => {
      return;
    };
    img.onerror = () => {
      return (img.src = IMAGE_PLACEHOLDER);
    };
  }, [src]);

  return (
    <div className={styles.avatar} style={style}>
      <img ref={imgRef} src={IMAGE_PLACEHOLDER} alt="avatar"></img>
    </div>
  );
}
