"use client";
import { useState } from "react";

export default function useModal() {
  const [modal, setModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string | null>(null);
  const [modalData, setModalData] = useState<any>(null);

  const handleModal = (type: string | null, data: any) => {
    setModal(Boolean(type));
    setModalData(data);
    if (type) {
      setModalType(type);
    }
  };

  return {
    modal,
    modalType,
    modalData,
    handleModal,
  };
}
