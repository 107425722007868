import { MouseEvent } from "react";
import styles from "./styles.module.css";
import { SearchInput } from "@/app/components/Buttons/SearchBtn";

function CancelBtn({
  handleModal,
}: {
  handleModal: (type: string | null, modalData: any) => void;
}) {
  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // Close the modal
    handleModal(null, null);
  };

  return (
    <button className={styles.cancelSearchBtn} onClick={handleOnClick}>
      Cancel
    </button>
  );
}

export default function SearchModal({
  handleModal,
}: {
  handleModal: (type: string | null, modalData: any) => void;
}) {
  return (
    <div className={styles.searchModalContainer}>
      <SearchInput />
      <CancelBtn handleModal={handleModal} />
    </div>
  );
}
