import { ethers } from "ethers";
import { PushAPI } from "@pushprotocol/restapi";
import { DHIVE_CHANNEL, PUSH_ENV } from "@/app/utils/constants";

export const getSigninInfo = async () => {
  try {
    const provider = new ethers.BrowserProvider((window as any).ethereum);
    if (!provider) {
      throw new Error("MetaMask wallet not installed");
    }

    const signer = await provider.getSigner();
    if (!signer) {
      throw new Error("Wallet not connected");
    }

    const address = await signer.getAddress();
    if (!address) {
      throw new Error("Wallet not connected");
    }

    const network = await provider.getNetwork();
    const chainId = network.chainId;
    if (!chainId) {
      throw new Error("Chain not found");
    }

    return { provider, signer, address, chainId };
  } catch (error) {
    throw error;
  }
};

export const getUserSubscriptions = async (account: string) => {
  try {
    const pushUser = await PushAPI.initialize(null, {
      account: account,
      env: PUSH_ENV,
    });
    const subscriptions = await pushUser.notification.subscriptions({
      channel: DHIVE_CHANNEL,
    });
    return subscriptions[0] || {};
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getUserNotifications = async (account: string, page: number) => {
  try {
    const pushUser = await PushAPI.initialize(null, {
      account: account,
      env: PUSH_ENV,
    });
    const notifications = await pushUser.notification.list("INBOX", {
      channels: [DHIVE_CHANNEL],
      raw: true,
      limit: 10,
      page: page,
    });
    return notifications || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
