import { useEffect } from "react";
import styles from "./styles.module.css";
import IoCheckmark from "@/app/components/Assets/Icons/IoCheckmark.svg";
import IoClose from "@/app/components/Assets/Icons/IoClose.svg";

export default function AlertModal({
  modal,
  modalType,
  modalData,
  handleModal,
}: {
  modal: boolean;
  modalType: string;
  modalData: any;
  handleModal: (type: string | null, modalData: any) => void;
}) {
  const icon =
    modalType === "success" ? (
      <IoCheckmark stroke={"#00800080"} width={20} height={20} />
    ) : (
      <IoClose fill={"#ca000080"} width={20} height={20} />
    );

  const closeModal = () => {
    handleModal(null, null);
  };

  // Effect with timeout to close the modal
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleModal(null, null);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [handleModal]);

  return (
    <>
      {modal && (
        <div className={styles.alertModal}>
          <div>
            {icon}
            <span>{modalData}</span>
            <IoClose
              fill={"var(--dark)"}
              width={20}
              height={20}
              onClick={closeModal}
            />
          </div>
        </div>
      )}
    </>
  );
}
